import PeriodItem from "./PeriodItem"

import edit from "../images/edit.svg"

const RateItem = ({ name, periods, handleFunction }) => {
    return (
        <div className='py-4'>
            <div className='p-10 w-full bg-gray-200 rounded-xl'>
                <div className='flex justify-between items-center mb-4'>
                    <div className='flex'>
                        <div className='bg-blue-700 w-2 mr-2'></div>
                        <div className='font-bold'>{name}</div>
                    </div>
                    <img src={edit} className="hover:bg-gray-300 p-2 rounded-xl" onClick={handleFunction}></img>
                </div>  
                
                <div className='flex justify-around flex-wrap'>
                    {periods?.map(item => <PeriodItem key={item.period} period={item.period} cost={item.cost} />)}
                </div>
            </div>
        </div>
    )
}

export default RateItem