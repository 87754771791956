export const setError = (value) => {
    return {
        type: "SET_ERROR",
        payload: value
    }
}

export const clearError = () => {
    return {
        type: "CLEAR_ERROR"
    }
}

export const setUser = (user) => {
    return {
        type: 'SET_USER', 
        payload: user
    }
}

export const setApiToken = (token) => {
    return {
        type: 'SET_API_TOKEN',
        payload: token
    }
}

export const setFetching = () => {
    return {
        type: 'SET_FETCHING'
    }
}

export const setFetched = () => {
    return {
        type: 'SET_FETCHED'
    }
}

export const setUrl = (url) => {
    return {
        type: 'SET_URL',
        payload: url
    }
}