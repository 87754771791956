import { useCallback } from "react";

import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setError } from "../redux/actions";

const useHttp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const request = useCallback(async (url, method = 'GET', body = null, apiToken = null, headers = {'Content-Type': 'application/json'}) => {
        if(apiToken) {
            headers = {
                ...headers, 
                'Authorization': `Bearer ${apiToken}`
            }
        }
        
        try {
            const response = await fetch(url, {method, body, headers});

            if (response.status === 324 || response.status === 401){
                navigate("/login")
                throw new Error("Не авторизован")
            }
            
            if (!response.ok) {
                const error = await response.json(); 
                throw new Error(error.message || `Could not fetch ${url}, status: ${response.status}`);
            }
            
            let data = await response.json();

            return data;
        } catch(e) {
            dispatch(setError(e.message))
            throw e;
        }
    }, []);
    return { request }
}

export default useHttp;
