import { useState, useEffect } from "react"

import useHttp from "../hooks/useHttp"

import { useSelector } from "react-redux"

import * as Yup from 'yup'
import { useFormik } from "formik"

import Layout from "../components/Layout"
import Consumption from "../components/Consumption"
import Spinner from "../components/Spinner"
import LineChart from "../components/LineChart"
import PeriodItem from "../components/PeriodItem"
import { isTimeInPeriod } from "../utils/isTimeInPeriod"
import { Parse } from "../utils/Parse"

//Becsuse of styles I can't use default component (refactor later mb)
const RateItem = ({ name, periods }) => {
        return (
            <div className='py-4 md:min-w-[528px]'>
                <div className='w-full bg-gray-200 rounded-xl'>
                    <div className='flex justify-between items-center mb-4'>
                        <div className='flex'>
                            <div className='bg-blue-700 w-2 mr-2'></div>
                            <div className='font-bold'>{name}</div>
                        </div>
                    </div>  
                    
                    <div className='flex justify-around flex-wrap'>
                        {periods?.map(item => <PeriodItem key={item.period} period={item.period} cost={item.cost} />)}
                    </div>
                </div>
            </div>
        )
    }

const validation = Yup.object().shape({
    device: Yup.string().required("Выберете устройство"), 
    rate: Yup.string().required("Выберете тариф"),
    date_from: Yup.date()
        .required('Введите дату')
        .max(Yup.ref('date_to'), 'Дата должна быть меньше'),
    date_to: Yup.date()
        .required('Введите дату')
        .min(Yup.ref('date_from'), 'Дата должна быть больше'),
})


const ReportsPage = () => {
    //Hooks
    const { request } = useHttp();


    //States
    const apiToken = useSelector(state => state.reducer.apiToken)
    const user = useSelector(state => state.reducer.user)

    const [rates, setRates] = useState([])
    const [devices, setDevices] = useState({})
    
    const [selectedRate, setSelectedRate] = useState({})
    const [history, setHistory] = useState()

    const [isLoading, setLoading] = useState(true)
    const [reportData, setReportData] = useState(null)

    //Fetch data
    useEffect(() => {
        if (apiToken) {
            request('https://shelly-66-eu.shelly.cloud/interface/device/get_all_lists', 'GET', null, apiToken)
                .then(res => setDevices(res.data.devices))
            
        }
    }, [apiToken])

    useEffect(() => {
        request('/api/getrates')
            .then(res => setRates(res))
    }, [])

    //Handle loading
    useEffect(() => {
        if(devices && rates) {
            setLoading(false)
        }
    }, [devices, rates])

    //Calculating consumption and sum
    useEffect(() => {
        if (history?.length > 0) {
            let sumPrice = 0, sumConsuption = 0;
            history.map(item => {
                const { consumption, datetime } = item;
                let price = 0;
                
                selectedRate.periods.map(item => {
                    if (isTimeInPeriod(datetime, item.period)) {
                        price = item.cost;
                        sumPrice += price * consumption / 1000;
                        sumConsuption += consumption / 1000;
                    }
                })
            })

            sumPrice = Number.parseFloat(sumPrice).toFixed(2)
            sumConsuption = Number.parseFloat(sumConsuption).toFixed(2)
    
            setReportData({ sumPrice, sumConsuption })
        }
    }, [history])

    //Formik
    const formik = useFormik({
        initialValues: {
            device: '', 
            rate: '',
            date_from: '',
            date_to: '',
        }, 
        validationSchema: validation,
        onSubmit: async (values) => {

            //Написана плохо потому что сервер шелли кривой, по факту это получение истории
            const { device, date_from, date_to, rate } = values;

            setSelectedRate(rates.filter(item => item.id == rate)[0])

            const requestArray = Parse(date_from, date_to);

            let historyData = [];

            const [prefix, postfix] = device.split('_')
            if (postfix) {
                await Promise.all(
                requestArray.map((item) =>
                request(`https://shelly-66-eu.shelly.cloud/statistics/relay/consumption?id=${prefix}&channel=${postfix}&date_range=custom&date_from=${item.from}&date_to=${item.to}`, "GET", null, apiToken)
                    .then((res) => {
                        res.data.history.map((item) => {
                            historyData.push(item);
                        })
                    })))
            } else {
                await Promise.all(
                requestArray.map((item) =>
                request(`https://shelly-66-eu.shelly.cloud/statistics/relay/consumption?id=${device}&channel=0&date_range=custom&date_from=${item.from}&date_to=${item.to}`, "GET", null, apiToken)
                    .then((res) => {
                        res.data.history.map((item) => {
                            historyData.push(item);
                        })
                    })))
            }
            
            setHistory(historyData);
        }
    })

    //Creating render array
    const deviceKeys = Object.keys(devices)
    const devicesList = deviceKeys.map(device => {
        return <option key={devices[device].id} value={devices[device].id}>{devices[device].name}</option>
    })
    const ratesList = rates.map(rate => {
        return <option key={rate.id} value={rate.id}>{rate.name}</option>
    })
    

    //Render 
    if (user?.shelly?.length === 0) {
        return (
            <Layout>
                <div className="text-4xl text-red-500 p-2 text-center">Привяжите аккаунт шелли!</div>
            </Layout>
        )
    }

    if(isLoading) {
        return(
            <Layout>
                <Spinner />
            </Layout>
        )
    }

    return(
        <Layout>
            <div className="flex justify-around flex-wrap">
                {reportData && selectedRate ? 
                    <div className="rounded-xl p-5 bg-gray-200 m-2 min-w-fit md:w-80">
                            
                        <LineChart data={history} />
                    
                        <div>
                            {selectedRate ? <RateItem key={selectedRate?.id} name={selectedRate?.name} periods={selectedRate?.periods} id={selectedRate?.id} /> : null}  
                        </div>
                        {reportData ? <div className="flex"><div className="mr-2 font-bold">Потребление:</div><Consumption value={reportData.sumConsuption} unit="кВт"/></div>: null}
                        
                        {reportData ? 
                            <div className="flex">
                                <div className="mr-2 font-bold">Цена:</div>
                                <div>{`${reportData.sumPrice} ₽`}</div>
                            </div>: null}
                    </div> 
                : null}

                <form className="w-80 bg-gray-200 rounded-xl p-5 m-2 h-fit" onSubmit={formik.handleSubmit}>
                    <select className="w-full h-10 rounded-md mb-2 bg-white" value={formik.values.device} name="device" id="device" onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value="">Устройство</option>
                        {devicesList}
                    </select>
                    {formik.errors.device && formik.touched.device ? <div className="text-red-500 text-center mb-2">{formik.errors.device}</div> : null}

                    <input max={new Date().toISOString().split('T')[0]} type="date" className="w-full h-10 rounded-md mb-2 bg-white" value={formik.values.date_from} name="date_from" id="date_from" onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.errors.date_from && formik.touched.date_from ? <div className="text-red-500 text-center mb-2">{formik.errors.date_from}</div> : null}

                    <input max={new Date().toISOString().split('T')[0]} type="date" className="w-full h-10 rounded-md mb-2 bg-white" value={formik.values.date_to} name="date_to" id="date_to" onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                    {formik.errors.date_to && formik.touched.date_to ? <div className="text-red-500 text-center mb-2">{formik.errors.date_to}</div> : null}
                    

                    <select className="w-full h-10 rounded-md mb-2 bg-white" value={formik.values.rate} name="rate" id="rate" onChange={formik.handleChange} onBlur={formik.handleBlur}>
                        <option value="">Тариф</option>
                        {ratesList}
                    </select>
                    {formik.errors.rate && formik.touched.rate ? <div className="text-red-500 text-center mb-2">{formik.errors.rate}</div> : null}


                    <button type="submit" className='flex justify-center w-full bg-blue-700 text-white py-2 px-4 rounded-xl'>Поиск</button>
                </form>
            </div>
        </Layout>
    )
}

export default ReportsPage