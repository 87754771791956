import { useState, useEffect } from 'react';

import clockSvg from "../images/clock.svg"

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
        setTime(new Date());
        }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex items-center mr-10'>                                                               {/* Has weired margin!*/}
        <img src={clockSvg} className="mr-2" />
        <div>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
    </div>
  );
}

export default Clock;