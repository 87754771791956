import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useHttp from "../hooks/useHttp";
import { useFormik } from "formik";
import * as Yup from 'yup';

import AuthInput from "../components/AuthInput";
import ErrorMessage from "../components/ErrorMessage";

const RegistrationPage = () => {
    const navigate = useNavigate();
    const { request } = useHttp();

    useEffect(() => {
        fetch('/api/aboutme').then(res => res.status === 200 ? navigate('/profile') : null)
    }, [])

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            realName: '',
        }, 
        validationSchema: Yup.object({
            email: Yup.string().email("Неправильный формат").required("Введите почту"),
            password: Yup.string().required("Введите пароль"),
            realName: Yup.string().required("Введите ваше имя"),
        }),
        onSubmit: values => {
            const body = {
                email: values.email, 
                password: values.password,
                fslName: values.realName
            }

            request('/api/registrate', 'POST', JSON.stringify(body))
                .then(res => navigate('/login'))
        }
    })

    return(
        <>
            <div className="flex justify-center items-center h-screen">
                <ErrorMessage /> 
                <div className="bg-gray-200 p-6 rounded-lg w-96">
                    <div className="flex mb-4">
                        <div className="bg-blue-700 w-2 mr-2"></div>
                        <div className="font-bold">Регистрация</div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <AuthInput placeholder="Почта" name="email" formik={formik} />
                        <AuthInput placeholder="Имя" name="realName" formik={formik} />
                        <AuthInput placeholder="Пароль" name="password" formik={formik} />
                        <button type="submit" className="block w-full h-10 text-center bg-blue-700 rounded-lg text-white">Зарегистрироваться</button>
                    </form>

                    <div className="flex text-xs mt-2">
                        <div className="mr-1">Уже есть аккаунт?</div>
                        <a href="/login" className="text-blue-700">Войдите!</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegistrationPage