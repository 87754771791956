import save from "../images/save.svg"

const SaveButton = () => {
    return (
        <button type="submit" className='flex justify-center w-full bg-blue-700 text-white py-2 px-4 rounded-xl'>
            <img src={save} className="mr-2" /> 
            <div>СОХРАНИТЬ</div>  
        </button>
    )
}

export default SaveButton