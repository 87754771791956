import { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { clearError } from "../redux/actions";

const ErrorMessage = ({ }) => {
  const { error } = useSelector(state => state.reducer)
  const dispatch = useDispatch();
  const onClose = () => dispatch(clearError())

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
      onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [onClose]);

  if (!error) {
    return null;
  }

  return (
    <div className="fixed top-0 right-0 w-300px h-16 px-4 flex items-center justify-center bg-red-500 text-white rounded transition-transform ease-in-out duration-300 transform translate-x-0">
      <p className="text-xl font-bold">{error}</p>
    </div>
  );
};

export default ErrorMessage;