const PeriodItem = ({ period, cost }) => {
    return (
        <div className='bg-white flex rounded-xl w-40 p-4 min-w-fit m-2'>
            <div className='bg-blue-700 w-1 mr-2'></div>
            <div className='text-center w-full'>
                <div>{period}</div>
                <div className='font-bold'>{cost} ₽</div>
            </div>
        </div>
    )
}

export default PeriodItem