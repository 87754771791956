import { useState, useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { setUser, setApiToken, setError, setUrl } from "../redux/actions"

import Clock from "./Clock"
import ErrorMessage from "../components/ErrorMessage"

import jwt_decode from "jwt-decode";

import useHttp from "../hooks/useHttp"

import menu from "../images/menu.svg"
import logo from "../images/logo.png"
import devices from "../images/devices.svg"
import rates from "../images/rates.svg"
import reports from "../images/reports.svg"
import hRates from "../images/h-rates.svg"
import hDevices from "../images/h-devices.svg"
import hReports from "../images/h-reports.svg"

const currentPath = window.location.pathname;

const LayoutItem = ({ name, link, icon, hIcon}) => {
    const [isHovered, setHovered] = useState(false)
    const [isSelected, setSelected] = useState(link === currentPath)

    return (
        <a href={link} className={`flex my-2.5 py-2.5 px-4 rounded transition duration-200 hover:bg-blue-700 hover:text-white ${isSelected ? 'bg-blue-700 text-white' : ''}`} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            {isHovered || isSelected ? <img src={hIcon} className="mr-2"></img> : <img src={icon} className="mr-2"></img>}
            <div>{name}</div>
        </a>
    )
}

const Layout = ({ children }) => {
    const { request } = useHttp();
    const dispatch = useDispatch();
    const user = useSelector(state => state.reducer.user)
    const apiToken = useSelector(state => state.reducer.apiToken)

    useEffect(() => {
        if(!user) {
            request(`/api/aboutme`)
                .then(res => dispatch(setUser(res)))
        }
    }, [user])

    useEffect(() => {
        const authToken = user?.shelly?.[0]?.token
        if(user && authToken && !apiToken) {
            request(`https://shelly-66-eu.shelly.cloud/oauth/auth?code=${authToken}&client_id=shelly-diy&grant_type=code`)
                .then(res => dispatch(setApiToken(res.access_token)))
                .catch(e => dispatch(setError(e.message)))
        }
    }, [user, apiToken])

    useEffect(() => {
        if (apiToken) {
            dispatch(setUrl(jwt_decode(apiToken).user_api_url))
        }
    }, [apiToken])

    const [isNavBar, setNavBar] = useState(true)
    const handleChangeNavbar = ()  => {
        setNavBar(!isNavBar)
    }

    const Profile = () => {
        return (
            <div className="w-9 h-9 rounded-full overflow-hidden">
                <a href="/profile">
                    {user ? <img src={`data:image/png;base64,${user.userImage}`} alt="Profile photo" className="object-cover" /> : null}
                </a>
            </div>
        )
    }

    return(
        <div className="relative min-h-screen md:flex">
            <ErrorMessage />
            {/* mobile menu  */}
            <div className="bg-white border-b flex justify-between">
                <div className="md:hidden flex align-center p-4">
                    <div className="mr-6">
                        <Profile />
                    </div>
                    <Clock />
                </div>

                <button onClick={handleChangeNavbar} className="p-4 focus:outline-none focus:bg-blue-700 md:hidden">
                    <img src={menu}></img>
                </button>
            </div>
            
            {/* desktop menu */}
            <div className={`${isNavBar ? '-translate-x-full' : ''} bg-white text-black w-64 p-4 absolute inset-y-0 left-0 transform transition duration-200 ease-in-out md:relative md:translate-x-0 border-r`}>
                <button>
                    <a href="/profile">
                        <img src={logo} alt="logo" />
                    </a>
                </button>

                <nav>
                    <LayoutItem name="Устройства" link="/devices" icon={devices} hIcon={hDevices}/>
                    <LayoutItem name="Тарифы" link="/rates" icon={rates} hIcon={hRates}/>
                    <LayoutItem name="Отчёты" link="/reports" icon={reports} hIcon={hReports}/>
                </nav>
            </div>

            {/* desktop header */}
            <div className="flex-1">
                <div className="w-full p-2 border-b md:flex justify-end hidden">
                    <Clock />
                    <Profile />
                </div>
                <div className="p-5 md:p-10">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;