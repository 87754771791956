import React, { useRef, useEffect } from "react";
import Chart from 'chart.js/auto';
import moment from 'moment';
import 'chartjs-adapter-moment';

const ChartAdapterMoment = {
  id: 'moment',
  adapters: {
    date: {
      id: 'moment',
      formats: {
        datetime: 'MMM D, hA',
        millisecond: 'h:mm:ss.SSS a',
        second: 'h:mm:ss a',
        minute: 'h:mm a',
        hour: 'hA',
        day: 'MMM D',
        week: 'll',
        month: 'MMM YYYY',
        quarter: '[Q]Q - YYYY',
        year: 'YYYY'
      }
    }
  },
  options: {
    parsing: false,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'MMM D, hA',
          },
        },
      },
    },
  },
};

const preprocessData = (data) => {
    let filteredData = [];

    for (let i = 0; i < data.length; i++) {
    const item = data[i];

    if(item.consumption > 0){
        filteredData.push(data[i-1])
        filteredData.push(item)
        filteredData.push(data[i+1])
        i+=2
    }
    }

    return filteredData;
};

const LineChart = ({ data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    Chart?.scaleService?.registerScaleType('time', Chart.scaleService.getScaleConstructor('time').extend({
      getTickLabelForIndex: function (index, tickValue) {
        return moment(tickValue).format('MMM DD');
      }
    }));
    Chart?.register(ChartAdapterMoment);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    // const filteredData = preprocessData(data);

    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: data.map((item) => item.datetime),
        datasets: [
          {
            label: "Потребление",
            data: data.map((item) => item.consumption),
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
          }
        ],
      },
      options: {
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
              displayFormats: {
                hour: "MMM D, hA",
              },
            },
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  
  return <canvas ref={canvasRef} className="w-full md:w-1/2"/>
};

export default LineChart;
