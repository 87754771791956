import { useEffect } from "react";

import useHttp from "../hooks/useHttp";

import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from 'yup';

import AuthInput from "../components/AuthInput";
import ErrorMessage from "../components/ErrorMessage"


const LoginPage = () => {
    const navigate = useNavigate();
    const { request } = useHttp();

    useEffect(() => {
        fetch('/api/aboutme').then(res => res.status === 200 ? navigate('/profile') : null)
    }, [])

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Введите логин"),
            password: Yup.string().required("Введите пароль")
        }),
        onSubmit: values => {
            const body = {
                userName: values.username,
                password: values.password
            }
            
            request(`/api/login`, 'POST', JSON.stringify(body))
                .then(res => {
                    navigate('/profile')
                })
        }
    }) 

    return (
        <>
            <div className="flex justify-center items-center h-screen">
                <ErrorMessage /> 
                <div className="bg-gray-200 p-6 rounded-lg w-96">
                    <div className="flex mb-4">
                        <div className="bg-blue-700 w-2 mr-2"></div>
                        <div className="font-bold">Вход</div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <AuthInput placeholder="Логин" name="username" formik={formik} />
                        <AuthInput placeholder="Пароль" name="password" formik={formik} />
                        <button type="submit" className="block w-full h-10 text-center bg-blue-700 text-white rounded-lg">Войти</button>
                    </form>
                    <div className="flex text-xs mt-2">
                        <div className="mr-1">Ещё нет аккаунта?</div>
                        <a href="/registration" className="text-blue-700">Зарегистрируйтесь!</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage;