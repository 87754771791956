import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const EmptyPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate("/profile")
    }, [])

    return(
        <div className="flex justify-center items-center h-screen">
            <div className="text-8xl font-bold">404</div>
        </div>
    )
}

export default EmptyPage