import { useState, useEffect } from "react"

import useHttp from "../hooks/useHttp"
import { useSelector } from "react-redux"

import Layout from "../components/Layout"
import Spinner from "../components/Spinner";
import PageTitle from "../components/PageTitle"
import Consumption from "../components/Consumption";
import { Switch } from "@mui/material";

import { decimalToHexString } from "../utils/decimalToHexString";
import { hexToDecimal } from "../utils/hexToDecimal";

const DevicesPage = () => {
    //Hooks
    const { request } = useHttp();

    //State
    const apiToken = useSelector(state => state.reducer.apiToken)
    const url = useSelector(state => state.reducer.url)
    const user = useSelector(state => state.reducer.user)

    const [devices, setDevices] = useState({});
    const [status, setStatus] = useState({});
    const [types, setTypes] = useState([]);
    const [socket, setSocket] = useState(null);

    const [isLoading, setLoading] = useState(true);
    const [isConnected, setConnected] = useState(false);

    //Connecting to sockets
    let urls = [ url.replace('https://', '') ];
    let sockets = [];


    useEffect(() => {
        if(apiToken) {
            urls.forEach(url => {
                if (url.length !== 0) {
                    let socket = new WebSocket(`wss://${url}:6113/shelly/wss/hk_sock?t=${apiToken}`)
                    sockets.push(socket)
                }
            })
        }

    }, [urls, apiToken, status])

    useEffect(() => {
        sockets.forEach(socket => {
            socket.onmessage = async (message) => {
                const data = await JSON.parse(message.data);
                if (data.event === "Shelly:StatusOnChange") {
                    const newStatus = data.status;
                    const item = decimalToHexString(data?.device?.id);

                    const newObj = {
                        ...status,
                        [item]: newStatus
                    };
                    setStatus(newObj);
                } else {
                    if (data.isok) {
                        const item = decimalToHexString(data?.device?.id);
                    }
                }
            };
        })

        return () => {
            sockets.forEach(socket => {
                socket.close()
            })
        }
    }, [sockets])

    //Fetch data
    useEffect(() => {
        if (apiToken) {
            request('https://shelly-66-eu.shelly.cloud/interface/device/get_all_lists', 'GET', null, apiToken)
                .then(res => setDevices(res.data.devices))
            request('https://shelly-66-eu.shelly.cloud/device/all_status', 'GET', null, apiToken)
                .then(res => setStatus(res.data.devices_status))
            request('/api/getdevicetypes')
                .then(res => setTypes(res))
        }
    }, [apiToken])

    //Handle loading state
    useEffect(() => {
        if (devices && status && types && (urls.length === sockets.length) && (devicesList.length !== 0 || offlineDevicesList.length !== 0)) {
            setLoading(false)
        }
    }, [devices, status, types, socket])


    //Turn on/off device
    const handleChangeState = (id, isOnline, relayCount = 0) => {
        const formData = new FormData()
        const server = new XMLHttpRequest()
        formData.append("id", id)
        formData.append("channel", relayCount)
        formData.append("turn", isOnline ? "off" : "on")

        server.open("POST", `${url}/device/relay/control`, true)
        server.setRequestHeader('Authorization', 'Bearer ' + apiToken)
        server.send(formData)
    }

    //Layout
    const DevicesItem = ({ name, image, id, power, isOnline, relayCnt, parsedId}) => {
        return (
            <div className="md:w-96 max-w-full bg-white md:p-5 p-2 rounded-xl flex justify-between min-w-fit mb-2">
                <img className="w-10 h-10 md:w-20 md:h-20" src={image}></img>
                <div className="center">
                    <div className={`font-bold truncate w-24 md:w-48 ${isOnline ? "text-green-700" : "text-red-700"}`}>{name}</div>
                    <Consumption value={power} unit="вт/ч"/>
                </div>
                < Switch checked = {
                    isOnline
                }
                onClick = {
                    () => handleChangeState(parsedId, isOnline, relayCnt)
                }
                />

            </div>
        )
    }

    const OfflineDeviceItem = ({ name, image, id }) => {
        return (
            <div className="md:w-96 max-w-full bg-white md:p-5 p-2 rounded-xl flex justify-between min-w-fit mb-2">
                <img className="w-10 h-10 md:w-20 md:h-20" src={image}></img>
                <div className="center">
                    <div className={`font-bold truncate w-24 md:w-48 text-gray-700`}>{name}</div>
                    <div>offline</div>
                </div>
                <Switch disabled/>
            </div>
        )
    }

    //Creating array of devices 
    const defaultImage = "https://cdn.vectorstock.com/i/preview-1x/48/06/image-preview-icon-picture-placeholder-vector-31284806.jpg"
    const devicesList = Object.keys(status).map((key) => {
        const device = devices[key]
        const image = types.find(item => item.deviceType === device?.type)?.deviceImage || defaultImage
            if(device?.channels_count === 1) {
                const id = device?.id
                const name = device?.name

                ///////////
                const server = device?.server
                if (server) {
                    urls.push(server)
                }
                //////////


                const { ison } = status[key]?.relays[0] || {}
                const power = status[key]?.meters[0]?.power
                return <DevicesItem isOnline={ison} key={id} id={id} name={name} image={image} power={power} relayCnt={0} parsedId={id} />
            } else {
                const prefix = device?.id.toString().split('_')[0]
                return status[prefix]?.relays?.map((relay, i) => {
                    const id = i === 0 ? device?.id : `${device?.id}_${i}`
                    const name = i === 0 ? device?.name : `${device?.name}_${i}`
                    const { ison } = status[key]?.relays[i] || {}
                    const power = status[key]?.meters?.[i]?.power

                    ///////////
                    const server = device?.server
                    if (server) {
                        urls.push(server)
                    }
                    //////////


                    return <DevicesItem isOnline={ison} key={id} id={id} name={name} image={image} power={power} relayCnt={i} parsedId={prefix} />
                }).flat()
            }
    })

    //Creating offline devices
    const offlineDevicesList = Object.keys(devices).map(key => {
        const device = devices[key]
        const [deviceId, postfix] = device.id.split("_"); 
        const image = types.find(item => item.deviceType === device?.type)?.deviceImage || defaultImage

        if (!Object.keys(status).includes(deviceId)) {
            return <OfflineDeviceItem name={device.name} image={image} id={deviceId} />
        }
    })


    //render
    if (user?.shelly?.length === 0) {
        return (
            <Layout>
                <PageTitle title={"Устройства"} />
                <div className="text-4xl text-red-500 p-2 text-center">Привяжите аккаунт шелли!</div>
            </Layout>
        )
    }
    
    if (isLoading) {
        return (
            <Layout>
                <PageTitle title={"Устройства"} />
                <Spinner />
            </Layout>
        )
    }

    return(
        <Layout>
            <PageTitle title={"Устройства"} />
            <div className="bg-gray-200 w-full md:p-5 p-4 rounded-xl mt-4 md:flex md:flex-wrap justify-around">
                {devicesList}
                {offlineDevicesList}
            </div>
        </Layout>
    )
}

export default DevicesPage
