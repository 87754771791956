import {memo} from "react"

import lightning from '../images/lightning.svg'

const Consumption = ({ value, unit }) => {
    return (
        <div className='flex'>
            <img className='mr-2' src={lightning}></img>
            <div>{`${value !== undefined ? value : "-"} ${unit}`}</div>
        </div>
    )
}

export default memo(Consumption)