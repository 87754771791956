const PageTitle = ({ title, children }) => {
    return (
        <div className="flex justify-between w-full text-3xl font-bold p-4 border-b">
            <div className="flex">
                <div className="bg-blue-700 w-2 mr-2"></div>
                {title}
            </div>
            {children}

        </div>
    )
}

export default PageTitle