import { Routes, Route } from "react-router-dom"

import LoginPage from "../pages/LoginPage"
import RegistrationPage from "../pages/RegistrationPage"
import ProfilePage from "../pages/ProfilePage"
import DevicesPage from "../pages/DevicesPage"
import RatesPage from "../pages/RatesPage"
import ReportsPage from "../pages/ReportsPage"
import EmptyPage from "../pages/EmptyPage"

import { useEffect } from "react"

function App() {
  useEffect(() => {
    document.title="Shelly energy"
  }, [])
  
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/devices" element={<DevicesPage />} />
      <Route path="/rates" element={<RatesPage />} />
      <Route path="/reports" element={<ReportsPage />} />
      <Route path="*" element={<EmptyPage />} />
    </Routes>
  )
}

export default App;
