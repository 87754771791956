import { useState, useEffect } from 'react'

import useHttp from '../hooks/useHttp'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import Layout from "../components/Layout"
import PageTitle from '../components/PageTitle'
import AuthInput from '../components/AuthInput'
import Modal from '../components/Modal'
import SaveButton from '../components/SaveButton'
import Spinner from "../components/Spinner"
import ErrorMessage from "../components/ErrorMessage"
import RateItem from '../components/RateItem'

import add from "../images/add.svg"

import { getMinutesFromTime } from '../utils/getMinutesFromTime'
import { formatTimeRange } from '../utils/formatTimeRange'
import { formatPeriods } from '../utils/formatPeriods'

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Введите название"),
    rates: Yup.array().of(
        Yup.object().shape({
            start: Yup.string().required("Введите начало периода"),
            end: Yup.string().required("Введите конец периода").test(
                "is-later",
                "Невозможный период",
                function (value) {
                    const { start } = this.parent;
                    const startMinutes = getMinutesFromTime(start);
                    const endMinutes = getMinutesFromTime(value);
                    return endMinutes > startMinutes;
                }
            ),
            cost: Yup.number().required("Введите стоимость").positive("Невозможная стоимость")
        })
    )
    .min(1, "Минимум один период")
    .max(3, "Максимум три периода")
    .test(
        "timePeriods", 
        "Невозможные промежутки",
        function(value) {
            const rates = value || []
            const validRates = rates.filter(rate => rate.start && rate.end)
            for (let i = 1; i < validRates.length; i++) {
                const prevEnd = getMinutesFromTime(validRates[i - 1].end)
                const currStart = getMinutesFromTime(validRates[i].start)
                if (currStart < prevEnd) return false
            }
            return true
        })
    .test(
      "covers-all-day",
      "Периоды не покрывают весь день",
      function (value) {
        const rates = value || [];
        const validRates = rates.filter((rate) => rate.start && rate.end);
        const totalMinutes = validRates.reduce(
          (acc, rate) => acc + (getMinutesFromTime(rate.end) - getMinutesFromTime(rate.start)),
          0
        );
        return totalMinutes === 24 * 60 - 1;
      }
    )
})


const RatesPage = () => {
    //Hooks
    const { request } = useHttp()
    
    //States
    const [data, setData] = useState([])
    const [editItem, setEditItem] = useState([])
    const [isModal, setModal] = useState(false)

    //Functions
    const handleModal = () => setModal(!isModal)

    const handleEdit = (id) => {
        setEditItem(data.filter(item => item.id === id))
        handleModal()
    }
    const handleNew = () => {
        setEditItem([])
        formik.resetForm()
        handleModal()
    }

    const addRate = () => {
        if (formik.values.rates.length < 3) {
            formik.setFieldValue("rates", [
                ...formik.values.rates,
                { start: "", end: "", cost: "" },
            ]);
        }
    }

    const removeRate = (index) => {
        const newRates = [...formik.values.rates];
        newRates.splice(index, 1);
        formik.setFieldValue("rates", newRates);
    }

    //Effects
    useEffect(() => {
        request('/api/getrates')
            .then(res => setData(res))
    }, [])

    useEffect(() => {
        if (editItem?.length !== 0) {
            formik.setFieldValue("name", editItem[0].name)     
            formik.setFieldValue("rates", formatPeriods(editItem[0].periods))
        }
    }, [editItem])

    const ratesList = data?.map(item => {
        return <RateItem key={item.id} name={item.name} periods={item.periods} handleFunction={() => handleEdit(item.id)} />
    })

    //Formik
    const formik = useFormik(({ 
        initialValues: {
            name: "", 
            rates: [{ start: "", end: "", cost: "" }]
        }, 
        validationSchema: validationSchema,
        onSubmit: values => {
        
            //Говнокод из-за специфики сервера и Кирилл пошёл нахуй))
            let body = [];
            let rates = values.rates.map(item => {
                return {period: formatTimeRange(item.start, item.end), cost: item.cost }
            })
            if (editItem.length !== 0) {
                body.push({
                    id: editItem[0].id,
                    name: values.name,
                    periods: rates
                })
            } else {
                body.push({
                    name: values.name,
                    periods: rates
                })
            }
            let obj = {rates: body}

            request('/api/updaterates', 'POST', JSON.stringify(obj))
                .then(res => {
                    if (res.status === "ok") {
                        setModal(false)
                        formik.resetForm();
                        window.location.reload();
                    }
                })
        }
    }))

    useEffect(() => {
        formik.values.rates.map((item, index) => {
            if(index + 1 === formik.values.rates?.length) {
                formik.setFieldValue(`rates[${index}].end`, "23:59")
            }
        })
        if (formik.values.rates.length === 1){
            formik.setFieldValue("rates[0].start", "00:00")
            // formik.setFieldValue("rates[0].end", "23:59")
        }
        if (formik.values.rates.length === 2) {
            const value = formik?.values?.rates?.[0]?.end
            formik.setFieldValue("rates[1].start", value)
        }

        if (formik.values.rates.length === 3) {
            const value1 = formik?.values?.rates?.[0]?.end
            formik.setFieldValue("rates[1].start", value1)

            const value2 = formik?.values?.rates?.[1]?.end
            formik.setFieldValue("rates[2].start", value2)
        }
    }, [formik?.values?.rates?.[0]?.end, formik?.values?.rates?.[1]?.end, formik.values.rates])

    //render
    if(!data) {
        return(
            <Layout>
                <Spinner />
            </Layout>
        )
    }

    return(
        <Layout>

            <ErrorMessage />
            <Modal name="Обновление/создание тарифа" isVisiable={isModal} onClose={handleModal}> 
                <form onSubmit={formik.handleSubmit}>
                    <AuthInput name="name" formik={formik} placeholder="Название" />
                    <div className='flex justify-between mb-2 font-bold'>
                        <div>Введите промежутки: </div>
                        <img src={add} onClick={() => addRate()}/>
                    </div>
                    {formik.values.rates.map((rate, index) => {
                        return (
                            <div key={index} className="flex justify-between p-2 mb-2">
                                <input disabled className={`disabled:bg-gray-400 w-24 h-50 px-2 py-1 bg-gray-300 rounded-lg text-center ${Array.isArray(formik.errors.rates) && formik.touched.rates && formik.errors.rates[index]?.start && formik.touched.rates[index]?.start ? 'border-2 border-red-600' : ''}`} id={`rates[${index}].start`} name={`rates[${index}].start`} value={formik.values.rates[index].start} type="time" onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                                <input disabled={index + 1 === formik.values.rates?.length} className={`disabled:bg-gray-400 w-24 h-50 px-2 py-1 bg-gray-300 rounded-lg text-center ${Array.isArray(formik.errors.rates) && formik.touched.rates && formik.errors.rates[index]?.end && formik.touched.rates[index]?.end ? 'border-2 border-red-600' : ''}`} id={`rates[${index}].end`} name={`rates[${index}].end`} value={formik.values.rates[index].end} type="time" onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                                {/* <input disabled={formik.values.rates?.length === 1} className={`disabled:bg-gray-400 w-24 h-50 px-2 py-1 bg-gray-300 rounded-lg text-center ${Array.isArray(formik.errors.rates) && formik.touched.rates && formik.errors.rates[index]?.end && formik.touched.rates[index]?.end ? 'border-2 border-red-600' : ''}`} id={`rates[${index}].end`} name={`rates[${index}].end`} value={formik.values.rates[index].end} type="time" onChange={formik.handleChange} onBlur={formik.handleBlur}></input> */}
                                <input className={`w-24 h-50 px-2 py-1 bg-gray-300 rounded-lg text-center ${Array.isArray(formik.errors.rates) && formik.touched.rates && formik.errors.rates[index]?.cost && formik.touched.rates[index]?.cost ? 'border-2 border-red-600' : ''}`} placeholder="Цена" id={`rates[${index}].cost`} name={`rates[${index}].cost`} value={formik.values.rates[index].cost} type="text" onChange={formik.handleChange} onBlur={formik.handleBlur}></input>
                                <button type="button" onClick={() => removeRate(index)}>-</button>
                            </div>
                        )
                    })}
                    {formik.errors.rates && !Array.isArray(formik.errors.rates) ? <div className="text-red-500 text-center mb-2">{formik.errors.rates}</div> : null}
                    <SaveButton />
                </form>
            </Modal>

            <PageTitle title="Тарифы">
                <img onClick={handleNew} src={add}></img>
            </PageTitle>

            <div>
                {ratesList}
            </div>
        </Layout>
    )
}

export default RatesPage