import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import useHttp from '../hooks/useHttp';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Layout from "../components/Layout"
import Spinner from '../components/Spinner';
import PageTitle from "../components/PageTitle"

import logout from "../images/logout.svg"
import settings from "../images/settings.svg"
import Modal from '../components/Modal';

import AuthInput from '../components/AuthInput';
import SaveButton from "../components/SaveButton"

const NoAccount = () => {
    return (
        <div className='w-full py-4 px-6 h-15 bg-gray-200 rounded-xl flex justify-between text-sm md:text-xl'>
            <div className=''>Аккаунт Shelly</div>
            <a href='https://my.shelly.cloud/oauth_login.html?response_type=code&client_id=shelly-diy&redirect_uri=http://localhost/api/linkshelly&scope=create&state=xcoiv'>
                <button className='bg-blue-600 text-white text-bold px-2 md:px-4 py-1 rounded-lg'>Привязать</button>
            </a>
        </div> 
    )
}

const AccountItem = ({ id }) => {
    return (
        <div className='w-full py-4 px-6 h-15 bg-gray-200 rounded-xl flex justify-between md:text-xl text-sm text-center'>
            <div className='py-1'>Аккаунт Shelly</div>
            <div className='py-1'>ID: {id}</div>
            <button className='bg-gray-600 text-white text-bold px-2 md:px-4 py-1 rounded-lg'>Отвязать</button>
        </div>
    )
}


const ProfilePage = () => {
    //Request hook
    const { request } = useHttp();
    const navigate = useNavigate();

    //User redux data
    const user = useSelector(state => state.reducer.user)

    //Modal 
    const [isModal, setModal] = useState(false)
    const handleModal = () => setModal(!isModal)  

    //Render accounts list
    const shellyList = user?.shelly?.map(item => {
        return <AccountItem key={item.userId} id={item.userId}/>
    })

    //Functions
    const handleLogout = () => {
        request(`/api/exit`)
            .then(() => navigate('/login'))
    }

    //Formik
    const formik = useFormik(({
        initialValues: {
            userName: '',
            realName: '',
            password: ''
        }, 
        validationSchema: Yup.object({
            userName: Yup.string().email("Неправильный формат"),
            realName: Yup.string(),
            password: Yup.string()
        }), 
        onSubmit: values => {
            const body = {
                newUserName: values.userName,
                newFSLName: values.realName,
                newPassword: values.password
            }

            request('/api/updateme', 'POST', JSON.stringify(body))
                .then(res => {
                    if (res.status === "ok") {
                        setModal(false)
                        formik.resetForm();
                        window.location.reload();
                    }                    
                })
        }

    }))

    useEffect(() => {
        if (user) {
            formik.setFieldValue("userName", user.userName)
            formik.setFieldValue("realName", user.fslName)
        }
    }, [user])

    //Render
    if (!user) {
        return(
            <Layout>
                <Spinner />
            </Layout>
        )
    }

    if (user) {
        return(
            <Layout>
                <PageTitle title="Профиль">
                    <button onClick={handleLogout}>
                        <img className="hover:bg-gray-200 p-1 rounded-xl" src={logout} alt="logout"></img>
                    </button>
                </PageTitle>


                <Modal name="Редактирование профиля" isVisiable={isModal} onClose={handleModal}>
                    <form onSubmit={formik.handleSubmit}>
                        <AuthInput name="userName" formik={formik} placeholder="Логин"/>
                        <AuthInput name="realName" formik={formik} placeholder="Имя"/>
                        <AuthInput name="password" formik={formik} placeholder="Пароль"/>
                        <SaveButton />
                    </form>
                </Modal>

                <div className="flex justify-between items-center mb-10 border-b py-10">
                    <div className="w-10 h-10 md:w-48 md:h-48 rounded-full overflow-hidden">
                        <img src={`data:image/png;base64,${user.userImage}`} alt="Profile photo" className="object-cover" />
                    </div>
                    <div className="text-left mr-auto ml-10 flex">
                        <div className='bg-blue-700 w-2 mr-2'></div>
                        <div>
                            <div className="font-bold text-2xl">{user.fslName}</div>
                           <div className="text-gray-400">{user.userName}</div>
                        </div>
                    </div>
                    <div>
                        <img src={settings} className="hover:bg-gray-200 p-1 rounded-xl" onClick={handleModal} alt="settings"></img>
                    </div>
                </div>

                

                {user?.shelly?.length === 0 ? <NoAccount /> : shellyList}
            </Layout>
        )
    }
}

export default ProfilePage