const initialState = {
    error: '', 
    user: '',
    apiToken: '', 
    url: '',
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case 'SET_ERROR':
            return {
                ...state, 
                error: action.payload
            }
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: ''
            }
        case 'SET_USER': 
            return {
                ...state, 
                user: action.payload
            }
        case 'SET_API_TOKEN':
            return {
                ...state,
                apiToken: action.payload
            }
        case 'SET_FETCHING': 
            return {
                ...state,
                loading: true
            }
        case 'SET_FETCHED': 
            return {
                ...state, 
                loading: false
            }
        case 'SET_URL':
            return {
                ...state,
                url: action.payload
            }
        default:
            return state
    }
}

export default reducer;