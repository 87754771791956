import close from "../images/close.svg"

const Modal = ({ isVisiable = true, onClose, name, children }) => {
    return (
        <>
            {!isVisiable ? null : 
                <div className="fixed top-0 bottom-0 left-0 right-0 w-full z-50 flex items-center justify-center backdrop-blur-md animate-appear">
                    <div className="block bg-gray-200 border-16 md:p-10 p-4 rounded-xl md:min-w-[500px] min-w-[350px]">
                        <div className="flex justify-between mb-4"> 
                            <div className="flex">
                                <div className="bg-blue-700 w-2 mr-2"></div>
                                <div className="md:text-xl text-sm font-bold">{name}</div>
                            </div>
                            <img src={close} onClick={onClose}></img>
                        </div>
                        <div>
                            {children}
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default Modal