export function isTimeInPeriod(timeStr, periodStr) {
    const [startStr, endStr] = periodStr.split('-');
    const [startHour, startMinute] = startStr.split('.').map(Number);
    const [endHour, endMinute] = endStr.split('.').map(Number);

    const time = new Date(timeStr);
    const timeMinutes = time.getHours() * 60 + time.getMinutes();

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    return startMinutes <= timeMinutes && timeMinutes <= endMinutes;
}
