export function Parse(from, to) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    const timeDelta = toDate.getTime() - fromDate.getTime();
    const daysDelta = Math.trunc(timeDelta / (24 * 60 * 60 * 1000));
    const monthDelta = Math.trunc(timeDelta / (30 * 24 * 60 * 60 * 1000));
    const yearsDelta = Math.trunc(timeDelta / (12 * 30 * 24 * 60 * 60 * 1000));
    let dates = [];
    let datesObj = [];

    for (let day = new Date(from); day.getTime() <= toDate.getTime(); day.setDate(day.getDate() + 2)) {
        let date = `${day.toISOString().substr(0, 10)}`;
        dates.push(date);
    }

    if (dates[dates.length - 1] != to)
        dates.push(`${toDate.toISOString().substr(0, 10)}`);

    for (let day = 0; day < dates.length; day++) {
        let date = {};
        date.from = dates[day];
        if (dates[day + 1] != undefined)
            date.to = dates[day + 1];
        datesObj.push(date);
        if (dates[day + 1] == to)
            break;
    }

    return datesObj;
}
