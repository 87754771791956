import spinnerSvg from "../images/spinner.svg"

const Spinner = () => {
    return (
        <div className="flex justify-center items-center h-full">
            <img src={spinnerSvg}></img>
        </div>
        
    )
}

export default Spinner;