function formatPeriod(period, cost) {
    const [start, end] = period.split('-');
    const formattedStart = start.replace('.', ':');
    const formattedEnd = end.replace('.', ':');
    return { start: formattedStart, end: formattedEnd, cost: cost };
}

export function formatPeriods(periods) {
    const formattedPeriods = periods.map(({ period, cost }) => {
        return formatPeriod(period, cost);
    });
    return formattedPeriods;
}
